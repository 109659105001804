@import './variable';

@media (max-width:640px) {
    body {
        font-size: 3.7vw;
        color: #1C1C1C;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-x: hidden;

        &.miniprogram {
            color: #121213;
        }

        #root,
        .page {
            height: 100%;
            width: 100%;
        }

        .ant-message .anticon {
            display: none;
        }

        .mini-header_login {
            float: right;

            button {
                color: @color_hover;
                padding: 1.6vw 4vw;
                border-radius: 4.53vw;
                line-height: calc(5.33vw - 2px);
                font-size: 3.2vw;
            }
        }

        .header {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 10vw;
            line-height: 10vw;
            background: #fff;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
            display: flex;
            z-index: 100;

            .header-left-label {
                position: absolute;
                left: 0;
                top: 0;
                width: 10vw;
                height: 100%;
                z-index: 2;

                &:before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 3vw;
                    height: 3vw;
                    border: 1px solid #1C1C1C;
                    border-radius: 2px;
                    border-right-color: transparent;
                    border-top-color: transparent;
                    transform: translate(-1.5vw, -1.7vw) rotate(45deg);
                }
            }

            .header-title {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                padding: 0 6px;
                text-align: center;
                font-size: 4vw;
            }

            .header-right-label {
                display: flex;
                justify-content: flex-end;

                i {
                    height: 10vw;
                    width: 10vw;
                    background-size: 50%;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                [data-type='download'] {
                    background-image: url('../images/nav_btn_download.png');
                }

                [data-type='feedback'] {
                    background-image: url('../images/nav_btn_edit.png');
                }

                [data-type='collect'] {
                    background-image: url('../images/nav_btn_collection.png');
                    background-size: 55%;
                }

                [data-type='collected'] {
                    background-image: url('../images/icon-collection-active.png');
                    background-size: 62%;
                }

                [data-type='translate'] {
                    background-image: url('../images/nav_btn_translate.png');
                    background-size: 62%;
                }
            }
        }

        .container {
            position: absolute;
            top: 10vw;
            left: 0;
            width: 100%;
            height: calc(100% - 10vw);
            overflow: auto;
            background: #fff;
            padding: 4vw;
        }

        .slider-box.on {
            opacity: 1;
            visibility: visible;
        }

        .slider-mask {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
        }

        .slider-wrap {
            position: fixed;
            background: #fff;
            transition: 0.5s cubic-bezier(0, 0.98, 0.37, 1);
            will-change: transform;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: auto;
            z-index: 1000;
        }

        .slider-left .slider-wrap {
            top: 0;
            left: 0;
            bottom: 0;
            transform: translateX(-100%);
        }

        .slider-bottom .slider-wrap {
            bottom: 0;
            left: 0;
            right: 0;
            transform: translateY(100%);
            border-radius: 2vw 2vw 0 0
        }

        .slider-left.on .slider-wrap {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        .slider-bottom.on .slider-wrap {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        .slider-title {
            color: #333;
            font-weight: 600;
            height: 10vw;
            line-height: 10vw;
            position: relative;
            font-size: 4.2vw;
            text-align: center;

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                transform: scaleY(.5);
                background: rgba(0, 0, 0, 0.1);
            }
        }

        .slider-close {
            position: absolute;
            right: 4vw;
            top: 50%;
            width: 3vw;
            height: 3vw;
            transform: translateY(-50%);
        }

        .slider-content {
            padding: 5vw 10vw;
        }

        .slider-btn {
            height: 12vw;
            line-height: 12vw;
            border-radius: 6vw;
            color: #fff;
            font-size: 4.0vw;
            text-align: center;
            background: #1062F3;
            width: 100%;

            .loading {
                display: inline-block;
                font-style: normal;
                vertical-align: baseline;
                text-align: center;
                text-transform: none;
                line-height: 1;
                text-rendering: optimizeLegibility;
                -webkit-font-smoothing: antialiased;
                margin-right: 5px;

                &:before {
                    content: "\E64D";
                    font-family: anticon !important;
                    display: inline-block;
                    animation: loadingCircle 1s infinite linear;
                }
            }
        }

        &.miniprogram .slider-btn {
            font-size: 4.3vw;
            background: rgba(125, 143, 252, 1);
            box-shadow: 0px 11px 32px 0px rgba(125, 143, 252, 0.5);
        }

        .freedown-tip+.modal-footer {
            padding-top: 1vw;
        }

        .top-area {
            text-align: center;
            padding: 4vw;
            background: #2A2E37;
            line-height: 1;

            .avatar-box {
                width: 15vw;
                height: 15vw;
                background: #57647E;
                border-radius: 50%;
                display: inline-block;
                overflow: hidden;
            }

            .info-box {
                line-height: 1;
                margin-top: 3vw;
                word-break: break-all;
            }
        }

        .middle-area {
            flex: 1;
        }

        .action-list {
            padding: 0 4vw;
        }

        .action-list>ul li {
            margin: 4vw 0;
            color: #DCE3E8;
            letter-spacing: 0;
            line-height: 1.3;
            text-align: center;
            button,a {
                background: transparent;
                color: inherit;
            }
        }

        .picker-popup-header {
            display: flex;
            padding: 0 5%;
            font-size: 4vw;
            height: 9vw;
            line-height: 9vw;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                transform: scaleY(0.5);
                background: rgba(0, 0, 0, 0.2);
            }

            .picker-popup-header-item {
                color: #75809D;
            }

            .picker-popup-header-title {
                color: #333;
                text-align: center;
                flex: 1;
            }

            .picker-popup-header-right {
                color: #1062F3;
            }
        }

        .btn {
            text-align: center;
            color: #fff;
            background: #1062F3;
            border-radius: 6px;
            box-shadow: 0px 9px 18px 0px rgba(76, 132, 255, 0.2);
            cursor: pointer;

            .loading {
                display: inline-block;
                font-style: normal;
                vertical-align: baseline;
                text-align: center;
                text-transform: none;
                line-height: 1;
                text-rendering: optimizeLegibility;
                -webkit-font-smoothing: antialiased;
                margin-right: 5px;

                &:before {
                    content: "\E64D";
                    font-family: anticon !important;
                    display: inline-block;
                    animation: loadingCircle 1s infinite linear;
                }
            }

            a {
                display: block
            }
        }

        .btn.blue-btn {
            color: #fff;
            background: rgba(16, 98, 243, 1);
            box-shadow: 0px 9px 18px 0px rgba(76, 132, 255, 0.2);
        }

        .btn.default-btn {
            background: #fff;
            color: #1062F3;
            line-height: 42px;
            border: 1px solid rgba(16, 98, 243, 1);
        }

        .h5-modal-wrap {
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            overflow: auto;
            outline: 0;
            -webkit-overflow-scrolling: touch;
        }

        .h5-modal-wrap.h5-zoom-enter {
            animation: zoomenter cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
        }

        .h5-modal-wrap.h5-zoom-leave {
            animation: zoomleave cubic-bezier(0.075, 0.82, 0.165, 1) .3s;
        }

        .h5-modal-wrap.h5-zoom-leaved {
            display: none;
        }

        @keyframes h5-zoomenter {
            0% {
                opacity: .7;
                transform: scale(.7);
            }

            100% {
                opacity: 1;
                transform: scale(1);
            }
        }

        @keyframes h5-zoomleave {
            0% {
                opacity: 1;
                transform: scale(1);
            }

            0% {
                opacity: .7;
                transform: scale(.7);
            }
        }

        .h5-modal {
            position: relative;
        }

        .h5-modal-content {
            padding: 7.3vw;
            padding-top: 0;
            position: relative;
            background-color: #fff;
            background-clip: padding-box;
            border: 0;
            border-radius: 3.2vw 3.2vw 0 0;
            overflow: hidden;

            .modal-btn-group {
                display: flex;
                border-top: 1px solid #ddd;
            }

            .modal-btn {
                flex: 1;
                box-shadow: none;
                border: none;
                border-radius: 0;
                border-left: 1px solid #ddd;
                background: #fff;
            }

            .blue-btn {
                color: @color_hover;
            }

            .default-btn {
                color: @color_grey;
            }

            .modal-btn:first-child {
                border-left: none;
            }
        }
    }
}