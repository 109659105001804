@import './variable';
@import './normalize';
@import './common';
@import './h5';
@import './nprogress';
@import './animation';
@import './reset';
@import './lib/modal';

.translate-page .ant-popover-inner-content {
    width: 180px;
    padding: 9px 12px;
}
.checkbox-group {
    display: inline-block;
    position: relative;
    input[type='checkbox'] {
        opacity: 0;
        position: relative;
        z-index: 2;
        width: 16px;
        height: 16px;
        @media screen and (max-width:640px) {
            width: 4vw;
            height: 4vw;
        }
    }
    input:checked+.checkbox-btn {
        background: url('../images/icon-mini-selected.png') no-repeat;
        background-size: 100%;
    }
    .checkbox-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        left:0;
        top: 0;
        z-index: 1;
        background: url('../images/icon-mini-select.png') no-repeat;
        background-size: 100%;
    }
}

.web-info {
    display: -ms-flexbox;
    display: flex;
    .left {
        margin-top: 3px;
        width: 42px;
        height: 42px;
        border-radius: 4px;
        margin-right: 14px;
        overflow: hidden;
        box-shadow:0px 0px 2px 0px rgba(235,237,242,1);
    }
    .right {
        width: 210px;
        .title {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 5px;
            font-weight: 400;
        }
        .desc {
            font-size: 12px;
            color: @color_content;
        }
    }
}

.article-info {
    .title {
        font-size: 16px;
        margin-bottom: 6px;
        color: @color_black;
    }
    .desc {
        color: @color_black;
        line-height: 23px;
        display: -webkit-box; 
        -webkit-line-clamp: 2; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        cursor: default;
    }
    .other {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .labels {
        display: -ms-flexbox;
        display: flex;
        margin-right: 10px;
    }
    .label {
        text-align: center;
        min-width: 80px;
        padding: 4px 12px;
        line-height: 20px;
        background:#F2F7FF;
        border-radius:3px;
        margin-right: 4px;
        color: @color_hover;
    }
    .url {
        color: @color_placeholder;
        line-height: 20px;
        padding: 4px 0;
        height: 28px;
        width: 236px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.no-data {
    height: 100%;
    position: relative;
    .content {
        height: 200px;
        position: absolute;
        left: 50%;
        top: 50%;
        text-align: center;
        transform: translate(-50%,-50%);
    }
    img {
        width: 100px;
        margin-bottom: 5px;
        transform: translateX(7px);
    }
}

.white-bg {
    background: #fff;
}