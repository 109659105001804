@keyframes antSlideUpIn {
    0% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }
}
@keyframes antSlideUpOut {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
        -webkit-transform-origin: 0% 0%;
        transform-origin: 0% 0%;
        opacity: 0;
    }
}
@keyframes loadingCircle {
    0% {
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}