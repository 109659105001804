* {
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
}

html {
    width: 100%;
    height: 100%;
    position: relative;
}

.clearfix {
    zoom: 1;
}

/*清除浮动*/
.clearfix:after {
    content: '';
    display: block;
    clear: both;
}

img {
    max-width: 100%;
    max-height: 100%;
}

/*公共样式*/
::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #96A4BB;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #96A4BB;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #96A4BB;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #96A4BB;
}

::placeholder {
    color: #96A4BB;
}

input,select,textarea {
    outline:none;
}
select,option,input{
    color: #031B4D;
}
input:focus,
select:focus,
textarea:focus {
    border-color:#1062F3;
}
select,input{
    -webkit-appearance: none;
}
input[type=number] {
    -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

button {
    cursor: pointer;
}

body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    min-width: 1280px;
    word-wrap: break-word;
    background: #F5F7F9;
    line-height: 1.5;
    font-size: 14px;
    color: #031B4D;
    height: 100%;
}
@media (max-width:640px) {
    body {
        width: 100%;
        min-width: 320px;
        overflow-x: hidden;
    }
}
#root,.pages {
    height: 100%;
    position: relative;
}

.page {
    min-height: calc(100% - 236px);
    background: #F5F7F9;
}

.wrapper {
    position: relative;
    height: 100%;
}

.container {
    margin: 0 auto;
    width: 1080px;
}

.hide {
    display: none !important;
}

.txt_cut {
    text-overflow:ellipsis; 
    overflow:hidden; 
    white-space:nowrap;
}

.lf {
    float: left;
}

.rf {
    float: right;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.not-select{
    -webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

input:hover, input:focus {
    border-color: #1062f3 !important;
}

@font-face {
    font-family: anticon;
    src: url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.eot");
    src: url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.eot?#iefix") format("embedded-opentype"),url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.woff") format("woff"),url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.ttf") format("truetype"),url("https://at.alicdn.com/t/font_zck90zmlh7hf47vi.svg#iconfont") format("svg")
}