.modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0,0,0,0.65);
}

.modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch;
}

.modal-wrap.zoom-enter {
    animation: zoomenter cubic-bezier(0.075, 0.82, 0.165, 1) .3s ;
}
.modal-wrap.zoom-leave {
    animation: zoomleave cubic-bezier(0.075, 0.82, 0.165, 1) .3s ;
}
@keyframes zoomenter {
    0% {
        opacity: .7;
        transform: scale(.7);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes zoomleave {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    0% {
        opacity: .7;
        transform: scale(.7);
    }
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    -webkit-box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    .modal-btn-group {
        text-align: center
    }
    .modal-btn {
        width:260px;
        height:44px;
        line-height:44px;
        text-align:center;
        color:#fff;
        background:#1062F3;
        // box-shadow:0px 6px 16px 0px rgba(19,109,249,0.3);
        border-radius:6px;
        cursor: pointer;
        .loading {
            display: inline-block;
            font-style: normal;
            vertical-align: baseline;
            text-align: center;
            text-transform: none;
            line-height: 1;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            margin-right: 5px;
            &:before {
                content: "\E64D";
                font-family: anticon !important;
                display: inline-block;
                animation: loadingCircle 1s infinite linear;
            }
        }
        a {
            display:block
        }
    }
    .modal-btn.blue-btn {
        color:#fff;
        background:rgba(16,98,243,1);
        box-shadow:0px 6px 16px 0px rgba(19,109,249,0.3);
    }
    .modal-btn.default-btn {
        background: #fff;
        color:#1062F3;
        line-height:42px;
        border:1px solid rgba(16,98,243,1);
    }
}

.modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 17px;
    height: 17px;
    background: url('../../images/icon-close-black.png');
    background-size: cover;
    transition: transform .3s;
}

.modal-close:hover{
    transform: rotate(-180deg);
}

.modal-header {
    padding: 16px 24px;
    color: rgba(0,0,0,0.65);
    background: #fff;
    border-radius: 4px 4px 0 0;
}

.modal-title {
    margin: 0;
    color: rgba(0,0,0,0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.modal-body {
    padding: 20px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
}

.modal-footer {
    padding-top: 20px;
    text-align: center;
    background: tranparent;
}

