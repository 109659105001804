@import '~antd/lib/date-picker/style/index.css';
@import '~antd/lib/input/style/index.css';
@import '~antd/lib/popover/style/index.css';
@import '~antd/lib/message/style/index.css';
@import '~antd/lib/menu/style/index.css';
@import '~antd/lib/skeleton/style/index.css';
@import '~antd/lib/spin/style/index.css';
@import '~antd/lib/alert/style/index.css';
@import './variable';

body {
    //popover
    .ant-popover.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
        right: 7px; 
        border-top-color: rgba(0, 0, 0, 0.7);
        border-right-color: rgba(0, 0, 0, 0.7);
    }
    .ant-popover .ant-popover-inner-content {
        color: #fff;
    }
    //alert
    .ant-alert-close-icon {
        transform: translate(5px,2px);
    }
    .ant-alert-close-icon .anticon-close{
        color: @color_title !important;
    }
    .ant-alert .anticon.anticon-info-circle {
        display: block;
        color: @color_hover !important; 
        transform: translateY(-2px);
    }
    // ant-calendar
    .ant-calendar-picker-icon {
        display: none;
    }

    .ant-calendar-picker-clear {
        opacity: 1;
        right: 4px;
    }

    // ant-input
    .ant-input-affix-wrapper {
        width: auto;

        .ant-input:not(:last-child) {
            padding-right: 20px;
        }

        .ant-input-suffix {
            right: 4px;
        }
    }

    .ant-input {
        &::placeholder {
            color: @color_placeholder;
        }

        color: #031B4D;
        border-color:#B3BED2;
    }

    // ant-popover
    .ant-popover-inner-content {
        background: rgba(0, 0, 0, .7);
        border-radius: 4px;
    }
    .ant-popover-message-title {
        font-size: 12px;
    }
    .ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow {
        border-bottom-color: rgba(0, 0, 0, .7);
        border-right-color: rgba(0, 0, 0, .7);
    }
    .ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow {
        border-top-color: rgba(0, 0, 0, .7);
        border-left-color: rgba(0, 0, 0, .7);
    }
    .ant-popover-message {
        color: #fff;
        padding: 0;
    }
    .ant-popover-message-title {
        padding-left: 0;
        text-align: center;
    }
    .ant-popover-inner-content {
        padding: 9px 15px;
        white-space: pre-wrap;
        max-width: 150px;
        font-size: 12px;
    }
    .ant-popover-buttons {
        display: none;
    }

    // ant-message
    .ant-message-notice-content {
        color: #fff;
        background: rgba(16, 98, 243, 1);
        box-shadow: 0px 0px 10px 0px rgba(19, 109, 249, 0.3);
        border-radius: 5px;
        text-align: center;
        max-width: 350px;
    }
    .ant-message .anticon {
        font-size: 14px;
    }
    .anticon{
        color: #fff !important;
    }
    .ant-input-suffix .anticon,
    .ant-calendar-picker .anticon{
        color: rgba(0,0,0,.25) !important;
    }
    .anticon.anticon-info-circle {
        display: none;
    }
    .anticon-spin::before {
            display: inline-block;
            -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
    }
    .anticon-spin {
            display: inline-block;
            -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
    }
    .ant-message {
        padding-top: 30px;
    }
    .ant-message-notice {
        animation: none;
    }
    // ant-menu
    .ant-menu {
        background: transparent;
    }
    .ant-menu-horizontal>.ant-menu-item>a {
        color: @color_title;
    }
    .ant-menu-horizontal>.ant-menu-item-selected>a {
        color: @color_hover;
    }
    .ant-menu-submenu-active .ant-menu-submenu-title {
        color: @color_hover;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
        color: @color_title;
    }
    .ant-menu-horizontal>.ant-menu-item,
    .ant-menu-horizontal>.ant-menu-submenu {
        border-bottom: none !important;
    }
    .ant-menu-submenu {
        border-radius: 2px;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
        padding: 0;
    }
    .ant-menu-item,
    .ant-menu-submenu {
        margin: 0 15px;
    }
    .ant-menu-vertical.ant-menu-sub,
    .ant-menu-vertical-left.ant-menu-sub,
    .ant-menu-vertical-right.ant-menu-sub {
        padding: 5px 0;
    }
    .ant-menu-submenu-popup .ant-menu-item {
        margin: 0;
        transition: all .3s;

        a {
            color: @color_title;
        }
    }
    .ant-menu-submenu-popup .ant-menu-item:hover {
        text-indent: 5px;
        background: rgba(16, 98, 243, .1);

        a {
            color: @color_hover;
        }
    }
    .ant-menu-submenu {
        transition: all .3s;
    }
    .slide-up-enter,
    .slide-up-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .slide-up-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }
    .slide-up-enter.slide-up-enter-active,
    .slide-up-appear.slide-up-appear-active {
        -webkit-animation-name: antSlideUpIn;
        animation-name: antSlideUpIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }
    .slide-up-leave.slide-up-leave-active {
        -webkit-animation-name: antSlideUpOut;
        animation-name: antSlideUpOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }
    .slide-up-enter,
    .slide-up-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .slide-up-leave {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
}

@media (max-width:640px) {
    body {
        .ant-message {
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
        }
    
        .ant-message-notice-content {
            padding: 5px 10px;
            background: rgba(0, 0, 0, .6);
            line-height: 25px;
            box-shadow: none;
            max-width: 60vw;
        }
    }
}
