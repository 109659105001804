.not-found {
    padding-top: 100px;
    height: 100%;
    text-align: center;
    .not-found-img {
      width: 500px;
    }

    .sub {
      display: inline-block;
      line-height: 40px;
      padding: 0 45px;
      margin: 25px 0;
      background: #2f96d6;
      border: 0;
      color: #FFF;
      font-size: 18px;
      text-decoration: none;
    }

    .p1 {
      font-size: 36px;
      font-weight: 700;
    }

    .p2 {
      color: #999;
    }
}

.kill-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 540px;
  height: 342px;
  margin: -171px -270px;
  z-index: 2001;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.kill-wrap,.kill-wrap .box1 {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #000;
  z-index: 2000;
}

.kill-wrap .box2 {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 540px;
  height: 342px;
  margin: -171px -270px;
  padding-top: 100px;
  background: url(../../images/kill_ie.png) no-repeat;
  z-index: 2001;
}
.kill-wrap .a {
  position: absolute;
  width: 30px;
  height: 30px;
}
.kill-wrap .a {
  right: 6px;
  top: 7px;
}
.kill-wrap .box2 .box3 {
  margin-top: 22px;
  height: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #414141;
}
.kill-wrap .box2 .box4 {
  margin: 24px 37px 0;
  text-align: justify;
  text-justify: distribute-all-lines;
  font-size: 0px;
}
.kill-wrap .box2 .box4:after {
  display: inline-block;
  width: 100%;
  content: " ";
}
.kill-wrap .box2 .box4 [data-rel=chrome].a2 {
  background: url('../../images/chrome.png') no-repeat;
}
.kill-wrap .box2 .box4 .a2 {
  display: inline-block;
  vertical-align: middle;
  width: 216px;
  height: 61px;
}
.kill-wrap .box2 .box4 [data-rel=firefox].a2 {
  background: url(../../images/firefox.png) no-repeat;
}
.kill-wrap .box2 .box4 .a2 {
  display: inline-block;
  vertical-align: middle;
  width: 216px;
  height: 61px;
}
